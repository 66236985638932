* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none!important;
}

.MuiList-root a.active {
  background-color: #3f51b5 !important;
  color: white !important;
}

.MuiList-root a.active .MuiListItemIcon-root svg {
  color: white !important;
}

.MuiDrawer-root>div {
  background-color: white;
}
.MuiTypography-body1 {
  font-size: 14px!important;
}

.MuiTypography-h6 {
  font-size: 1rem!important;
}

.MuiTabPanel-root {
  padding: 24px 0!important;
}

.MuiButton-root,
.MuiTableCell-root {
  font-size: 14px!important;
  text-transform: none!important;
}

.MuiTable-root img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.MuiButton-containedPrimary:hover {
  color: #fff;
}

.amount-wrapper {
  box-shadow: 1px 1px 7px 3px #e8e8e8;
  border-radius: 7px;
  padding: 16px 20px;
  margin-bottom: 16px;
}

.amount-wrapper .amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-part {
  display: flex;
}

.left-part .amounts {
  font-size: 28px;
  font-weight: 600;
  color: #1a74ec;
}

.devider {
  width: 1px;
  height: 44px;
  background: #cfcfcf;
  margin: auto 16px;
}

.reach {
  font-size: 16px;
  color: #656668;
}

.reach div:first-child {
  margin-bottom: 7px;
}

.amount-wrapper span.input-group-text {
  background: #fff;
  border: 0;
}

.amount-wrapper input {
  height: 20px;
  width: 20px;
}

.budget {
  background: #f1f2f6;
  display: flex;
  justify-content: space-between;
  padding: 16px 10px;
  margin-top: 20px;
  border-radius: 7px;
}

.budget h6 {
  margin-bottom: 2px;
}

.budget div div {
  color: #656668;
  font-size: 14px;
}

.total {
  font-size: 16px!important;
  color: #000!important;
}

.card-deatils img {
  height: 27px;
  width: 33px;
  object-fit: contain;
}

.card-deatils {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.card-side b {
  font-size: 15px;
}

.card-side {
  display: flex;
  align-items: center;
}

.promote-now .btn-primary {
  background-color: #1a74ec;
}

.card-image {
  background-color: #eeeeee;
  padding: 42px;
}

.card-image img {
  max-width: 100%;
}

.question-mark {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  background: #007bff;
  color: #fff;
  border-radius: 50%;
}

.card-text-bottom {
  color: #007bff;
  font-size: 16px;
}


/* .promote-now {
position: fixed;
bottom: 0;
width: 93%;
right: 0;
left: 6%;
}*/

.wrapper {
  height: 70vh;
  padding: 0px;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #666;
  /* display: inline-block !important; */
  float: none;
}

.wrapper ul {
  font-size: 10px;
  padding: 0px;
  list-style: none;
  letter-spacing: 2px;
  text-align: center;
}

.wrapper ul li a {
  display: block;
  text-decoration: none;
  color: #000;
  border-bottom: 1px solid #666;
  padding: 20px;
  font-size: 14px;
  letter-spacing: initial;
  cursor: pointer;
}

.wrapper ul li a.active {
  background-color: #3f51b5 !important;
  color: #fff !important;
}


/* .campaign-menu a:nth-child(2) {
border-bottom: 1px solid rgba(0, 0, 0, 0.12);
margin-left: 0!important;
} */

.campaign-menu a:nth-child(2) svg {
  margin-left: 16px;
}

.category-wrapper {
  background-color: white;
  padding: 25px;
  box-shadow: silver 0px 0px 15px;
}

.modal-content .category-wrapper .wrapper ul li a {
  padding: 12px;
  font-size: 14px;
}

.modal-content .category-wrapper {
  box-shadow: none;
}

.modal-content .wrapper h5 {
  font-size: 14px;
}

.campaign-menu .MuiAccordionSummary-root.Mui-expanded {
  padding: 0;
}

.campaign-menu .MuiAccordionSummary-root,
.campaign-menu .MuiAccordionSummary-root.Mui-expanded {
  padding: 0 16px 0 0;
}

.campaign-menu .MuiAccordionSummary-content.Mui-expanded,
.campaign-menu .MuiAccordionSummary-content {
  margin: 0;
}

.campaign-menu .MuiAccordionDetails-root .MuiTypography-body1 {
  font-size: 15px;
}

.campaign-menu .MuiAccordionDetails-root {
  padding: 0;
  display: block;
}

.makeStyles-appBar-4 {
  z-index: 1000!important;
}

.MuiDrawer-paper {
  z-index: 1000!important;
}

.sslider-wrapper {
  width: 80%;
  margin: auto;
  position: relative;
  padding-top: 150px;
}

.carousel .thumb {
  transition: border .15s ease-in;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 0!important;
  min-width: 85px;
  max-height: 85px;
  object-fit: cover;
  min-height: 85px;
}

.carousel .thumb img {
  vertical-align: top;
  min-width: 85px;
  max-height: 85px;
  object-fit: cover;
  min-height: 85px;
}

.carousel .slide img {
  width: 100%;
  vertical-align: top;
  border: 0;
  max-height: 400px;
  object-fit: cover;
  min-height: 400px;
}

ul.control-dots {
  display: none;
}

p.carousel-status {
  display: none;
}

.delete-icon {
  background: #fff;
  border: 0;
  z-index: 99;
  right: 0px;
  position: absolute;
}

.carousel .control-disabled.control-arrow {
  display: block!important;
  opacity: 1!important;
}

button.control-arrow.control-next {
  opacity: 1!important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #3f51b5!important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #3f51b5!important;
}

.pdf-page {
  position: relative;
  margin: 0 auto;
  padding: 0.4in 0.3in;
  color: #333;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

.pdf-header {
  padding-bottom: 0.05in;
  margin-bottom: 0.3in;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invoice-number {
  padding-top: 0.1in;
  float: right;
}

.pdf-footer {
  position: absolute;
  bottom: 0.25in;
  left: 0.3in;
  right: 0.3in;
  padding-top: 0.05in;
  /* border-top: 1px solid #e5e5e5; */
  font-size: 0.9em;
  text-align: left;
  color: #787878;
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.addresses {
  font-size: 12px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addresses:after {
  display: block;
  content: "";
  clear: both;
}

.for {
  float: left;
  width: 40%;
}

.from {
  float: right;
  width: 60%;
}

.addresses p {
  color: #1e222e;
  padding: 0.05in 0;
  border-top: 1px solid #e5e5e5;
  margin: 0;
  font-weight: 600;
  font-size: 12px;
}

.addresses p:first-of-type {
  border-top: 0;
}

.pdf-body {
  margin-top: 0.3in;
}

.company-logo {
  font-size: 14px;
  font-weight: bold;
  color: #1e222e;
}

.signature {
  padding-top: 0.3in;
}


/* Dimensions other than px and em should be divided by 1.33 for the proper PDF output */

.size-a4 {
  width: 6.2in;
  height: 8.7in;
}

.size-letter {
  width: 6.3in;
  height: 8.2in;
}

.size-executive {
  width: 5.4in;
  height: 7.8in;
  font-size: 12px;
}

.size-executive .pdf-header {
  margin-bottom: 0.1in;
}

.box {
  padding: 1em;
  background-color: rgba(20, 53, 80, 0.038);
  border: 1px solid rgba(20, 53, 80, 0.05);
}

.description-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dotted #e5e5e5;
}

.description-details {
  font-size: 12px;
  white-space: nowrap;
  width: 480px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
}

p.description-timeline {
  font-size: 11px;
}

.balance {
  font-size: 14px;
  font-weight: 600;
}

.order-total {
  display: flex;
  padding: .4rem 0;
  border-bottom: 2px solid #dee2e6;
}

.order-total .balance {
  font-weight: 400;
  font-size: 12px;
}

.order-total>div {
  font-size: 12px;
}

.note {
  font-size: 10px;
}

.store-table tr td .MuiCheckbox-root {
  display: none;
}
.table-exclude-search .MuiToolbar-regular {
  display: none;
}

.reviewViewForm {
  width: 400px;
  height: 400px;
  /*min-height: 500px;*/
  padding: 50px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 60%);
}

.reviewViewFormNew {
  width: 600px;
  height: 500px;
  /*min-height: 500px;*/
  padding: 50px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 60%);
}

.billImageView {
  width: 700px;
  /*min-height: 500px;*/
  padding: 50px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 60%);
}

.ratingForm {
  margin-left: 2rem;

}
.ratingForm .MuiSvgIcon-root {
  width: 2em;
  height: 2em;
  
}


.sidebar ul li {
  list-style: none;
  font-size: 15px;
  line-height: 28px;
  white-space: wrap;
}

/* .col-md-3.sidebar ul {
  position: fixed;
} */

.help-bar {
  background-color: #3f51b5;
  top: 0;
  left: auto;
  right: 0;
  /* position: fixed; */
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  z-index: 1000!important;
  min-height: 64px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-between;
}
.sidebar ul li a {
  color: #3f51b5;
    font-weight: 400;
  
}

/* li.is-current a {
  color: #3f51b5!important;
} */
.help-content section {
  margin-bottom: 36px;
}

.help-content p {
  font-size: 14px;
  margin-bottom: 20px;
}

.help-content h3,.sidebar h4 {
  font-weight: 400!important;
  color: #3e4153!important
}

.help-content ul li{
  font-size: 15px;
  font-weight: 400;
  color: #212529;
  line-height:1.5rem;
}
.help-content img {
  max-width: 100%;
  transition: transform ease-in .5s;
}

[data-rmiz-btn-open] {
  cursor: zoom-in!important;
}
[data-rmiz-btn-close] {
  cursor: zoom-out!important;
}
.help-content ul{
  padding-left: 2rem;
}
/* .help-content {
  max-height: 600px;
  overflow-y: scroll;
  position: fixed;
  width: 50%; 
} */

.help-content h3 {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}
.help-content p {
  margin-top: 10px;
  margin-bottom: 1rem;
  color: #212529;
  line-height: 1.5;
}
.help-content::-webkit-scrollbar,.sidebar::-webkit-scrollbar {
  display:none;
}
.sidebar {
  overflow-y: scroll;
}

.search-banner {
  height: 150px;
  background-image: url("./assets/search-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display:flex;
  align-items: center;
}
.backtoptop {
  position: fixed;
  bottom: 32px;
  right: 32px;
}
.help-content ul li {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  list-style: auto;
}
.sidebar p{
  font-size: 16px!important;
}
@media only screen and (max-width:768px){
  .help-content {
    position: static;
    max-width: 100%; 
    width: 100%; 
  }
  .search-banner {
    height: 200px;
  }
  .search-banner h1 {
    font-size: 18px;
}
.search-banner input::placeholder {
  font-size: 14px;
}
}




#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.paymentButton {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.paymentButton:hover {
  filter: contrast(115%);
}

.paymentButton:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.table-exclude-search canvas {
  height: 350px!important;
}

.date-filter {
  position: absolute;
  right: 0;
  top: -10px;
}

.table-exclude-search {
  position: relative;
}

.date-filter input {
  max-width: 148px;
}

.date-filter .form-group {
  margin-bottom: 0;
}

.date-filter button {
  background: #3f51b5;
  border-color: #3f51b5;
}

.productFilter {
  max-width: 450px;
}

.shopifyBatch{
margin-top: 2px;
margin-left: 145px;
width: 380px;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
